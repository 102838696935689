import React, { Component } from "react";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import { HashLink as Link } from "./utils/react-router-hash-link";
import logoNav from "../../imgs/logo1.png";
var _t = require("counterpart");

class MainNavbar extends Component {
  LocalehandleChange(lc) {
    counterpart.setLocale(lc);
  }

  render() {
    console.log(<Translate content="menu.channel" />);
    console.log(<Translate content="menu.transparency" />);
    return (
      <nav id="main-nav" className="navbar">
        <div className="container">
          <Link to={"/"} className="navbar-brand">
            <img src={logoNav} alt="Logo Além da Cura" />
          </Link>
          <div className="navbar-header header">
            <button
              type="button"
              id="btn-menu-bar"
              className="btn btn-default navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#div-menu"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="menu collapse navbar-collapse" id="div-menu">
            <ul className="transl-icon-mobile nav navbar-nav">
              <li className="transl-icon-m">
                <button
                  onClick={() => this.LocalehandleChange("pt")}
                  alt="Português"
                  title="Português"
                >
                  PT
                </button>
              </li>
              <li className="transl-icon-m"> | </li>
              <li className="transl-icon-m">
                <button
                  onClick={() => this.LocalehandleChange("en")}
                  alt="English"
                  title="English"
                >
                  EN
                </button>
              </li>
              <li className="transl-icon-m"> | </li>
              <li className="transl-icon-m">
                <button
                  onClick={() => this.LocalehandleChange("es")}
                  alt="Español"
                  title="Español"
                >
                  ES
                </button>
              </li>
            </ul>
            <ul id="main-menu" className="nav navbar-nav">
              <li>
                <Link to={_t("navegation.welcome")}>
                  <Translate content="menu.home" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.psel")}>
                  <Translate content="menu.psel" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.our-map")}>
                  <Translate content="menu.our-map" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.about")}>
                  <Translate content="menu.about" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.products")}>
                  <Translate content="menu.products" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.team")}>
                  <Translate content="menu.team" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.press")}>
                  <Translate content="menu.press" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.partners")}>
                  <Translate content="menu.partners" />
                </Link>
              </li>
              <li>
                <Link to={_t("navegation.contribute")}>
                  <Translate content="menu.contribute" />
                </Link>
              </li>
              <li>
                <a
                  href="https://docs.google.com/spreadsheets/d/1x4hI19tyNSdgWhG2YnyMD2yguaYT38rO-4VG9i_IGZc/edit#gid=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Translate content="menu.transparency" />
                </a>
              </li>
            </ul>
            <ul id="social-menu" className="nav navbar-nav pull-right">
              <li>
                <a
                  href="https://www.facebook.com/alemdacura/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-facebook menu-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/alemdacura/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-instagram menu-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCAkq7vrZ7xXkAHe30rmZeNA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-youtube-play menu-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>
              <li className="transl-icon">
                <button
                  onClick={() => this.LocalehandleChange("pt")}
                  alt="Português"
                  title="Português"
                >
                  PT
                </button>
              </li>
              <li className="transl-icon"> | </li>
              <li className="transl-icon">
                <button
                  onClick={() => this.LocalehandleChange("en")}
                  alt="English"
                  title="English"
                >
                  EN
                </button>
              </li>
              <li className="transl-icon"> | </li>
              <li className="transl-icon">
                <button
                  onClick={() => this.LocalehandleChange("es")}
                  alt="Español"
                  title="Español"
                >
                  ES
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default MainNavbar;
