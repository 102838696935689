import counterpart from 'counterpart'
import base from './base'

function getBrowserLocale() {
    return (navigator.languages && navigator.languages[0])
      || navigator.userLanguage || navigator.language || navigator.browserLanguage || navigator.systemLanguage || '';
}

export default async function TranslationsConfig() {
    const translations = await base.fetch('translations', {
        context: this,
        asArray: true
      }).then(data => {
        console.log(data)
        return data
      }).catch(error => {
        console.log("fetch translations error: ", error)
        return false
      })
      
  console.log('data', translations)
    const translationEn = translations[0]
    const translationEs = translations[1]
    const translationPt = translations[2]

    counterpart.registerTranslations('pt', translationPt)
    counterpart.registerTranslations('en', translationEn)
    counterpart.registerTranslations('es', translationEs)

    const defaultLocale = "pt"
    
    counterpart.setLocale(defaultLocale);
} 
