import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SponsorsList from './sponsorslist'

class ModalSponsorsList extends Component {

    renderSponsors() {
        let targetList = document.getElementById("modal-sponsorslist")
        SponsorsList.map( (s, i) => {
            let sponsor = document.createElement('p');
            sponsor.setAttribute('key', `${i}`)
            sponsor.innerText = `${s}`
            return targetList.appendChild(sponsor) 
        })
    }

    componentDidUpdate() {
        if (this.props.show) {
            this.renderSponsors()    
        }
    }

    render() {

        if(!this.props.show) {
            return null;
        }

        return (
            <section id="modal-sponsors-list" className="modalvideo-backdrop" onClick={this.props.onClose}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-container">
                        <header className="modal-header">
                            <button type="button" className="close" onClick={this.props.onClose}><span aria-hidden="true">&times;</span></button>
                        </header>
                        <div className="modal-body">
                            <div id="modal-sponsorslist" ref="slist"></div> 
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

ModalSponsorsList.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalSponsorsList