import $ from 'jquery';

$.fn.instagram = function(options) {
    var Instagram = function(target, options) {
        this.domTarget = $(target);
        this.options = $.extend({
            user_id: options[0],
            access_token: options[1],
            media_count: 9,
        }, options || {});
        this.images = [];
        this.getImages();
    };

    Instagram.prototype = {

        getImages: function() {
            var opts = this.options;
            $.ajax({
                type: 'GET',
                dataType: 'jsonp',
                cache: false,
                url: 'https://api.instagram.com/v1/users/' +
                        opts.user_id + '/media/recent/?access_token=' +
                        opts.access_token + '&count=' +
                        opts.media_count,
                success: $.proxy(function(json) {
                    var items = json.data;
                    if (json.meta.code !== 200) {
                        this.displayPlaceHolder()
                    }
                    $.each(items, $.proxy(function(i, item) {
                        this.images.push({
                            name: item.caption ? item.caption.text : 'Instagram photo',
                            link: item.link,
                            smallSrc: item.images.low_resolution.url
                        });
                    }, this));
                    this.displayImages();
                }, this)
            });
        },

        displayImages: function() {
            $.each(this.images, $.proxy(function(i, elm) {
                var
                    img = $(new Image()),
                    link = $('<a/>')
                        .attr('title', elm.name)
                        .attr('href', elm.link)
                        .attr('target', '_blank')
                        .append(img
                            .attr('src', elm.smallSrc)
                        ),
                    div = $('<div/>')
                        .attr('class', 'div-img')
                        .append(link);
                img.on('load', $.proxy(function() {
                    if (i === 0 || i === 1 || i === 2) {
                        this.domTarget = $("#div-content1");
                    } else if (i === 3 || i === 4 || i === 5) {
                        this.domTarget = $("#div-content2");
                    } else {
                        this.domTarget = $("#div-content3");
                    }
                    this.domTarget.append(div);
                }, this));  
            }, this));
        },

        displayPlaceHolder: function() {
            $('.div-place-holder').css('display', 'block');
        }

    };

    var i;
    this.each(function() {
        i = new Instagram(this, options);
    });
    return i;
};

