import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoadingIcon from '../../../imgs/loading-icon2.gif'

class ModalForm extends Component {

    constructor(props) {
        super(props)
        this.state = { isLoading: true }
        this.onLoad = this.onLoad.bind(this)
    }

    onLoad() {
        document.getElementById("loading").style.display = "none"
    }

    componentDidUpdate() {
        if (this.props.show) {
            this.refs.iframe.addEventListener('load', this.onLoad);
        }
    }   

    render() {

        if(!this.props.show) {
            return null;
        }

        return (
            <section id="modal-contrib-form" className="modalvideo-backdrop" onClick={this.props.onClose}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-container">
                        <header className="modal-header">
                            <button type="button" className="close" onClick={this.props.onClose}><span aria-hidden="true">&times;</span></button>
                        </header>
                        <div className="modal-body">
                            <img id="loading" src={LoadingIcon} alt="Loading Icon" className="img-responsive" />
                            <div className="embed-responsive embed-responsive-16by9" style={{minHeight: 400 + "px"}}>
                                <iframe ref="iframe" title="modalform" className="embed-responsive-item"
                                    src={this.props.iframesrc} 
                                    allowFullScreen
                                    frameBorder="0">
                                </iframe>    
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

ModalForm.propTypes = {
    iframesrc: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalForm