import React, { Component } from 'react'
import $ from 'jquery'
import ReactGA from 'react-ga'
import './dependencies'
import '../style/App.css'
import translationsConfig from './translations-config'

import logoFull from '../imgs/logo.png'

import MainNavbar from './template/mainnavbar'
import Footer from './template/footer'
import Routes from './main/routes'

window.jQuery = window.$ = $
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.min')

export default class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isFetchingTranslations: true
        }
        this.loadTranslation = this.loadTranslation.bind(this)
    }

   async loadTranslation() {
        await translationsConfig()
        this.setState({isFetchingTranslations: false})
    }

    componentDidMount() {
        this.loadTranslation()
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
      }

    render() {
        if(this.state.isFetchingTranslations) {
            return (
                <div id="preview-page">
                    <div className="preview-logo">
                        <img src={logoFull} alt="Preview logo"/>
                        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style={{fontSize: '16px'}}></i>    
                    </div>  
                </div>
            )
        }
        return (
            <div>
                <MainNavbar/>
                <Routes/>
                <Footer/>
            </div>
        )
    }
}
