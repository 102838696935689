import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../template/home'
import Contribute from '../template/contribute'
var _t = require('counterpart')

export default props => (
    <Switch>
        <Route exact path='/' component={Home}/>
        <Route path={_t('navegation.contribute')} component={Contribute}/>
        <Redirect from='*' to="/" />
    </Switch>
)