import React, { Component } from 'react'
import Translate from 'react-translate-component'
import MapVideos from './ourmap/mapvideos'

import ArgImg from '../../../imgs/ourmap/argentina.JPG'
import GerImg from '../../../imgs/ourmap/alemanha.JPG'
import BraImg from '../../../imgs/ourmap/brasil.JPG'
import FraImg from '../../../imgs/ourmap/franca.JPG'
import PorImg from '../../../imgs/ourmap/portugal.JPG'
import AfrImg from '../../../imgs/ourmap/africa_do_sul.jpg'
import KenImg from '../../../imgs/ourmap/quenia.jpg'
import AusImg from '../../../imgs/ourmap/australia.jpg'
import IndImg from '../../../imgs/ourmap/india.jpg'

const _t = require('counterpart')

export default class OurMap extends Component {

    constructor(props) {
        super(props)
        this.state = { selected: false, country: "" }
        this.handleHover = this.handleHover.bind(this)
        this.handleLeave = this.handleLeave.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.renderMapVideos = this.renderMapVideos.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    refresh() {
        this.setState({ selected: false })
        document.getElementById("base-map").style.display = "block"
        document.getElementById("arg-map").style.display = "none"
        document.getElementById("bra-map").style.display = "none"
        document.getElementById("fra-map").style.display = "none"
        document.getElementById("ger-map").style.display = "none"
        document.getElementById("por-map").style.display = "none"
        document.getElementById("aus-map").style.display = "none"
        document.getElementById("ken-map").style.display = "none"
        document.getElementById("ind-map").style.display = "none"
        document.getElementById("afr-map").style.display = "none"

    }

    handleHover(ca3) {
        if (this.state.selected === false) {
            document.getElementById("base-map").style.display = "none"
            document.getElementById(`${ca3}-map`).style.display = "block"
        }
    }

    handleLeave() {
        if (this.state.selected === false) {
            this.refresh()
        }
    }

    handleClick(country) {
        this.refresh()
        this.setState({ selected: true, country: country })
        this.renderMapVideos()
    }

    renderMapVideos() {
        if (this.state.selected === true) {
            let countryImg
            if (this.state.country === "argentina") {
                countryImg = ArgImg
            } if (this.state.country === "germany") {
                countryImg = GerImg
            } if (this.state.country === "brasil") {
                countryImg = BraImg
            } if (this.state.country === "france") {
                countryImg = FraImg
            } if (this.state.country === "portugal") {
                countryImg = PorImg
            } if (this.state.country === "kenya") {
                countryImg = KenImg
            } if (this.state.country === "southafrica") {
                countryImg = AfrImg
            } if (this.state.country === "india") {
                countryImg = IndImg
            } if (this.state.country === "australia") {
                countryImg = AusImg
            } 
            
            return (
                <MapVideos img={countryImg} country={this.state.country} />
            )
        }
    }

    render() {
        return (
            <section id={_t("sec-ids.our-map")} className="commom-sec sec-our-map container-fluid">
                <div className="parallax">
                    <div className="container">
                        <div className="parallax-text">
                            <p><i className="fa fa-quote-left" aria-hidden="true"></i> 
                            <Translate content="ourmap.parallax-text" />
                             <i className="fa fa-quote-right" aria-hidden="true"></i></p>
                            <p className="signature pull-right"><Translate content="ourmap.parallax-signature" /></p>
                        </div>
                    </div>
                </div>
                <div className="section-body">
                    <div className="container">
                        <div className="content">
                            <h1><Translate content="ourmap.sec-title" /></h1>
                            <p><Translate content="ourmap.sec-description" /></p>
                        </div>
                        <div className="content">
                            <div className="countries-list">
                                <ul>
                                    <li><p><Translate content="ourmap.call-to-list" /></p></li>
                                    <li><a onClick={() => this.handleClick("brasil")} onMouseOver={() => this.handleHover("bra")} onMouseLeave={this.handleLeave}><Translate content="ourmap.brasil" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("portugal")} onMouseOver={() => this.handleHover("por")} onMouseLeave={this.handleLeave}><Translate content="ourmap.portugal" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("france")} onMouseOver={() => this.handleHover("fra")} onMouseLeave={this.handleLeave}><Translate content="ourmap.france" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("germany")} onMouseOver={() => this.handleHover("ger")} onMouseLeave={this.handleLeave}><Translate content="ourmap.germany" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("argentina")} onMouseOver={() => this.handleHover("arg")} onMouseLeave={this.handleLeave}><Translate content="ourmap.argentina" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("southafrica")} onMouseOver={() => this.handleHover("afr")} onMouseLeave={this.handleLeave}><Translate content="ourmap.southafrica" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("kenya")} onMouseOver={() => this.handleHover("ken")} onMouseLeave={this.handleLeave}><Translate content="ourmap.kenya" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("australia")} onMouseOver={() => this.handleHover("aus")} onMouseLeave={this.handleLeave}><Translate content="ourmap.australia" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                    <li><a onClick={() => this.handleClick("india")} onMouseOver={() => this.handleHover("ind")} onMouseLeave={this.handleLeave}><Translate content="ourmap.india" /> <i className="fa fa-plane" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="content">
                            <div id="base-map" className="maps-ac">
                                {this.renderMapVideos()}
                            </div>
                            <div id="arg-map" className="maps-ac"></div>
                            <div id="bra-map" className="maps-ac"></div>
                            <div id="fra-map" className="maps-ac"></div>
                            <div id="ger-map" className="maps-ac"></div>
                            <div id="por-map" className="maps-ac"></div>
                            <div id="aus-map" className="maps-ac"></div>
                            <div id="ken-map" className="maps-ac"></div>
                            <div id="ind-map" className="maps-ac"></div>
                            <div id="afr-map" className="maps-ac"></div> 
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}