import React from "react";
import Webseries from "./home/webserie";
import Welcome from "./home/welcome";
import OurNumber from "./home/ournumber";
import OurMap from "./home/ourmap";
import About from "./home/about";
import Partners from "./home/partners";
import Team from "./home/team";
import Press from "./home/press";
import Products from "./home/products";

export default (props) => (
  <div>
    <Webseries />
    <Welcome />
    <OurMap />
    <Products />
    <About />
    <OurNumber />    
    <Team />
    <Press />
    <Partners />
  </div>
);
