import React, { Component } from 'react'
import Translate from 'react-translate-component'
import PropTypes from 'prop-types'
const _t = require('counterpart')

class MapVideos extends Component {
    
        constructor(props) {
            super(props)
            this.state = { 
                NewSrc: "", 
                isOpen: false,
                 }
            this.openModal = this.openModal.bind(this)
            this.closeModal = this.closeModal.bind(this)
        }

        openModal(src) {
            this.setState({ 
                NewSrc: src,
                isOpen: true
             })
            document.body.style.overflow = 'hidden';
        }

        closeModal() {
            this.setState({ 
                NewSrc: "",
                isOpen: false
             })
            document.body.style.overflow = 'unset';
        }

        render() {
            return (
                <div className="mapvideos">
                    <h1><Translate content={`ourmap.${this.props.country}`}/></h1>
                    <div className="content">
                        <div className="map-country-text">
                            <p><Translate content={`ourmap.${this.props.country}-text`}/></p>
                        </div>
                        <div className="map-country-img">
                        <img src={this.props.img} alt={_t(`ourmap.${this.props.country}-text-img`)} className="img-responsive img-thumbnail" />
                            <p><Translate content={`ourmap.${this.props.country}-text-img`}/></p>
                        </div>
                    </div>
                    <div className="content">
                        <div className="videos-soon">
                            <h2><Translate content="ourmap.videos-soon" /></h2>
                        </div>
                    </div>
                </div>
            )
        } 
}

MapVideos.propTypes = {
    img: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
  };

  export default MapVideos