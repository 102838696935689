import React, { Component } from 'react'
import Translate from 'react-translate-component'
import Cers from '../../../imgs/partners/partners_cers.jpg'
import ICT from '../../../imgs/partners/partners_ICT.png'
import ICS from '../../../imgs/partners/partners_ICS.png'
import ONU from '../../../imgs/partners/partners_OnuMulheres.png'
import pfizer from '../../../imgs/partners/partners_pfizer.png'
import Pulsara from '../../../imgs/partners/partners_Pulsara.png'
import Refazenda from '../../../imgs/partners/partners_Refazenda.png'
import Sebrae from '../../../imgs/partners/partners_sebrae.jpg'
import Sosten from '../../../imgs/partners/partners_Sosten.png'
import TEDX from '../../../imgs/partners/partners_ted_x.png'
import AlemDoRosa from '../../../imgs/press/alemdorosa2017.png'
import AlemDaViagem from '../../../imgs/press/alemdaviagem.jpg'

var _t = require('counterpart')

export default class partners extends Component {
    
    render() {
        return (
            <section id={_t("sec-ids.partners")} className="commom-sec sec-partners container-fluid">
                <div className="section-body">
                    <div className="container">
                        <div className="content partners-links">
                            <h2><Translate content="partners.title" />:</h2>
                            <ul className="partners-icons-list">
                                <li className="icon-journal">
                                    <a title="Cers" target="_blank" rel="noopener noreferrer" >
                                        <img src={Cers} alt="Simbolo da Cers" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="ICT" target="_blank" rel="noopener noreferrer" >
                                        <img src={ICT} alt="Simbolo do ICT" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a  title="ICS" target="_blank" rel="noopener noreferrer">
                                        <img src={ICS} alt="Simbolo do ICS" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a  title="ONUmulheres" target="_blank" rel="noopener noreferrer">
                                        <img src={ONU} alt="Simbolo da Onu mulheres" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="Pfizer" target="_blank" rel="noopener noreferrer">
                                        <img src={pfizer} alt="Simbolo da pfizer" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="Pulsara" target="_blank" rel="noopener noreferrer">
                                        <img src={Pulsara} alt="Simbolo da Pulsara" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="Refazenda" target="_blank" rel="noopener noreferrer">
                                        <img src={Refazenda} alt="Simbolo da Refazenda" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="Sebrae" target="_blank" rel="noopener noreferrer">
                                        <img src={Sebrae} alt="Simbolo do Sebrae" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="Sosten" target="_blank" rel="noopener noreferrer">
                                        <img src={Sosten} alt="Simbolo da Sosten" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a title="TEDX" target="_blank" rel="noopener noreferrer">
                                        <img src={TEDX} alt="Simbolo da TEDX" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="content partners-campaigns">
                            <h2><Translate content="press.last-camp" />:</h2>
                            <div className="campaign-box">
                                <div className="camp-img">
                                <img src={AlemDoRosa} alt="Campanha X" className="img-responsive" style={{ paddingTop: 32 +"px"}} />
                                </div>
                                <div className="camp-text">
                                    <h3><Translate content="press.camp1-title" /></h3>
                                    <p><Translate content="press.camp1-description" /></p>
                                    <a href="https://www.facebook.com/events/480064199058170/?acontext=%7B%22source%22%3A5%2C%22page_id_source%22%3A845608232190214%2C%22action_history%22%3A[%7B%22surface%22%3A%22page%22%2C%22mechanism%22%3A%22main_list%22%2C%22extra_data%22%3A%22%7B%5C%22page_id%5C%22%3A845608232190214%2C%5C%22tour_id%5C%22%3Anull%7D%22%7D]%2C%22has_source%22%3Atrue%7D" target="_blank" rel="noopener noreferrer" className="btn btn-camp-link pull-right"><Translate content="press.camp-button" /></a>
                                </div>
                            </div>
                            <div className="campaign-box">
                                <div className="camp-img">
                                <img src={AlemDaViagem} alt="Campanha X" className="img-responsive" />
                                </div>
                                <div className="camp-text">
                                <h3><Translate content="press.camp2-title" /></h3>
                                <p><Translate content="press.camp2-description" /></p>
                                <a href="https://alemdaviagem.46graus.com/" target="_blank" rel="noopener noreferrer" className="btn btn-camp-link pull-right"><Translate content="press.camp-button" /></a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
