import React, { Component } from 'react'
import Translate from 'react-translate-component'
import Globo from '../../../imgs/press/press_globo.jpg'
import Catraca from '../../../imgs/press/press_catraca-livre.jpg'
import RPA from '../../../imgs/press/press_rpa.jpg'
import DiarioPE from '../../../imgs/press/press_diario-pe.jpg'
import JC from '../../../imgs/press/press_jc.jpg'
import FolhaPE from '../../../imgs/press/press_folha-pe.jpg'
import OGlobo from '../../../imgs/press/press_oglobo.jpg'
import AlgoMais from '../../../imgs/press/press_algomais.jpg'
import PorAqui from '../../../imgs/press/press_poraqui.jpg'
import FreiCan from '../../../imgs/press/press_freicaneca.jpg'
import TVJC from '../../../imgs/press/press_tvjc.jpg'

var _t = require('counterpart')

export default class Press extends Component {
    
    render() {
        return (
            <section id={_t("sec-ids.press")} className="commom-sec sec-press container-fluid">
                <div className="parallax">
                    <div className="container">
                        <div className="parallax-text">
                            <p><i className="fa fa-quote-left" aria-hidden="true"></i> 
                            <Translate content="press.parallax-text" />
                             <i className="fa fa-quote-right" aria-hidden="true"></i></p>
                            <p className="signature pull-right"><Translate content="press.parallax-signature" /></p>
                        </div>
                    </div>
                </div>
                <div className="section-body">
                    <div className="container">
                        <div className="content press-description">
                            <h1><Translate content="press.sec-title" /></h1>
                            <p><Translate content="press.sec-description" /></p>
                        </div>
                        <div className="content bottom-press-materials">
                            <div className="press-materials">
                                <a href="https://www.flickr.com/photos/alemdacura/sets/72157689162982976" role="button" target="_blank" rel="noopener noreferrer">
                                    <Translate content="press.file1-name" /> <i className="fa fa-flickr" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div className="press-materials">
                                <a href="https://docs.google.com/document/d/1H4_oM-fN1GF0EKr-bqgSjeHYwmlRXfLW07MO58aDVXI/edit?usp=sharing" role="button" target="_blank" rel="noopener noreferrer">
                                    <Translate content="press.file2-name" /> <i className="fa fa-file-text" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div className="content press-links">
                            <h2><Translate content="press.news" />:</h2>
                            <ul className="press-icons-list">
                                <li className="icon-journal">
                                    <a href="https://oglobo.globo.com/sociedade/saude/documentario-reune-relatos-de-mulheres-ao-redor-do-mundo-que-enfrentaram-cancer-22017178" title="Globo" target="_blank" rel="noopener noreferrer" >
                                        <img src={Globo} alt="Simbolo da Globo" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="https://oglobo.globo.com/sociedade/filme-traz-relatos-de-mulheres-que-lutam-contra-cancer-de-mama-23186108" title="OGlobo" target="_blank" rel="noopener noreferrer" >
                                        <img src={OGlobo} alt="Simbolo da OGlobo" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="https://catracalivre.com.br/geral/cidadania/indicacao/alem-da-cura-documentario-valoriza-historias-de-mulheres-que-lutam-contra-o-cancer/" title="Catraca Livre" target="_blank" rel="noopener noreferrer">
                                        <img src={Catraca} alt="Simbolo do Catraca Livre" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="http://razoesparaacreditar.com/beleza/alem-da-cura-projeto-busca-empoderar-mulheres-que-lutam-contra-o-cancer/" title="Razões para Acreditar" target="_blank" rel="noopener noreferrer">
                                        <img src={RPA} alt="Simbolo da Razões para Acreditar" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="http://www.diariodepernambuco.com.br/app/noticia/vida-urbana/2015/12/04/interna_vidaurbana,614287/projeto-busca-financiamento-para-documentario-sobre-o-cancer.shtml" title="Diário de PE" target="_blank" rel="noopener noreferrer">
                                        <img src={DiarioPE} alt="Simbolo do Diário de PE" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="https://blogs.ne10.uol.com.br/casasaudavel/2018/04/06/alem-da-cura-promove-evento-gratuito-na-vespera-do-dia-mundial-de-combate-ao-cancer/" title="Jornal do Comércio" target="_blank" rel="noopener noreferrer">
                                        <img src={JC} alt="Simbolo do JC" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="https://www.folhape.com.br/diversao/diversao/diversao/2018/06/05/NWS,70709,71,552,DIVERSAO,2330-ALEM-CURA-BUSCA-RECURSOS-PARA-FINALIZACAO-PROJETO-SOBRE-MULHERES-COM-CANCER.aspx" title="Folha PE" target="_blank" rel="noopener noreferrer">
                                        <img src={FolhaPE} alt="Simbolo da Folha PE" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="https://poraqui.com/saude-e-bem-estar/contribua-alem-da-cura-da-voz-a-mulheres-com-cancer-em-documentario/" title="Por Aqui" target="_blank" rel="noopener noreferrer">
                                        <img src={PorAqui} alt="Simbolo da Por Aqui" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="http://revista.algomais.com/noticias/projeto-alem-da-cura-da-voz-as-mulheres-ao-falar-sobre-o-cancer" title="Algo Mais" target="_blank" rel="noopener noreferrer">
                                        <img src={AlgoMais} alt="Simbolo da Algo Mais" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="http://www.freicanecafm.org/salada-pop-outubro-de-2018" title="Radio Frei Caneca Mais" target="_blank" rel="noopener noreferrer">
                                        <img src={FreiCan} alt="Simbolo da Frei Caneca" />
                                    </a>
                                </li>
                                <li className="icon-journal">
                                    <a href="https://www.youtube.com/watch?v=bUqP6mdWLmY" title="TV JC" target="_blank" rel="noopener noreferrer">
                                        <img src={TVJC} alt="Simbolo TVJC" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

