import React, { Component } from 'react'
import Translate from 'react-translate-component'
import SponsorsList from './sponsorslist'
import ModalSponsorsList from './modalsponsorslist'

export default class Sponsors extends Component {

    constructor(props) {
        super(props)
        this.state = { openModalList: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal() {
        this.setState({ openModalList: true })
        document.body.style.overflow = 'hidden';
    }

    closeModal() {
        this.setState({ openModalList: false })
        document.body.style.overflow = 'unset';
    }

    renderSponsors() {
        let targetList
        let counter = 0
        let divId = 1
        SponsorsList.map( (s, i) => {
            if (counter === 20) {
                counter = 0
                divId += 1
            }
            targetList = document.getElementById(`sl${divId}`) 
            let sponsor = document.createElement('p');
            sponsor.setAttribute('key', `${i}`)
            sponsor.innerText = `${s}`
            counter += 1
            return targetList.appendChild(sponsor) 
        })
    }

    componentDidMount() {
        this.renderSponsors()
    }

    render() {
        return (
            <section id="sponsors" className="sec-contrib container-fluid">
                <div className="container">
                    <div className="sponsors-header">
                        <h1><Translate content="sponsors.sponsors-title" /></h1>
                        <h2><Translate content="sponsors.sponsors-subtitle" /></h2>
                    </div>
                    <div className="sponsors-body">
                        <div id="carousel-sponsors" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner" role="listbox">
                                <div className="item active">
                                    <div id="sl1" className="sponsors-list"></div>
                                    <div id="sl2" className="sponsors-list"></div>
                                    <div id="sl3" className="sponsors-list"></div>
                                    <div id="sl4" className="sponsors-list"></div>
                                    <div id="sl5" className="sponsors-list"></div>
                                </div>
                                <div className="item">
                                    <div id="sl6" className="sponsors-list"></div>
                                    <div id="sl7" className="sponsors-list"></div>
                                    <div id="sl8" className="sponsors-list"></div>
                                    <div id="sl9" className="sponsors-list"></div>
                                    <div id="sl10" className="sponsors-list"></div>
                                </div>
                                <div className="item">
                                    <div id="sl11" className="sponsors-list"></div>
                                    <div id="sl12" className="sponsors-list"></div>
                                    <div id="sl13" className="sponsors-list"></div>
                                    <div id="sl14" className="sponsors-list"></div>
                                    <div id="sl15" className="sponsors-list"></div>
                                </div>
                                <div className="item">
                                    <div id="sl16" className="sponsors-list"></div>
                                    <div id="sl17" className="sponsors-list"></div>
                                    <div id="sl18" className="sponsors-list"></div>
                                    <div id="sl19" className="sponsors-list"></div>
                                    <div id="sl20" className="sponsors-list"></div>
                                </div>
                                <div className="item">
                                    <div id="sl21" className="sponsors-list"></div>
                                    <div id="sl22" className="sponsors-list"></div>
                                    <div id="sl23" className="sponsors-list"></div>
                                    <div id="sl24" className="sponsors-list"></div>
                                    <div id="sl25" className="sponsors-list"></div>
                                </div>
                                <div className="item">
                                    <div id="sl26" className="sponsors-list"></div>
                                    <div id="sl27" className="sponsors-list"></div>
                                    <div id="sl28" className="sponsors-list"></div>
                                    <div id="sl29" className="sponsors-list"></div>
                                    <div id="sl30" className="sponsors-list"></div>
                                </div>
                            </div>
                            <a id="arrowleft" className="left carousel-control" href="#carousel-sponsors" role="button" data-slide="prev">
                                <span aria-hidden="true"><i className="fa fa-chevron-left" aria-hidden="true"></i></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a id="arrowright" className="right carousel-control" href="#carousel-sponsors" role="button" data-slide="next">
                                <span aria-hidden="true"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                        <div className="mobile-list visible-xs-block">
                            <button onClick={this.openModal}>Ver lista completa</button>
                        </div>
                    </div>
                </div>
                <ModalSponsorsList 
                    show={this.state.openModalList}
                    onClose={this.closeModal}
                />
            </section>
        )
    }
}