import React from 'react'
import Translate from 'react-translate-component'
import Youtube from 'react-youtube'
import ReactPlayer from 'react-player/youtube'

import Poster from '../../../imgs/video-fallback.jpg'
import MainVideo from '../../../videos/video.mp4'

import { Progress } from 'react-sweet-progress'
import "react-sweet-progress/lib/style.css"

var _t = require('counterpart')

export default props => (
    
    <section id={_t("sec-ids.welcome")} className="commom-sec sec-welcome container-fluid">
        <div className="welcome container">
            <h4><Translate content="home.we-interviewed" /></h4>
            <div>
                <h2><Translate content="contribute.shopping-donating-title" /></h2>
                <p><Translate content="contribute.shopping-donating-description" /></p>
                <div className="contrib-button">
                    <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=9NX3R3XX8EVCW&lc=BR&item_name=Alem%20da%20Cura&item_number=Alem%20da%20Cura&currency_code=BRL&bn=PP%2dDonationsBF%3abtn_donateCC_LG%2egif%3aNonHosted" className="btn" target="_blank" rel="noopener noreferrer" >
                        <Translate content="contribute.shopping-donating-button" /></a>
                </div>
            </div>
        </div>

        <video autoPlay muted loop poster={Poster}>
           <source src={MainVideo} type="video/mp4"/>
            <img src={Poster} 
                alt={_t("home.poster-alt")} 
                title={_t("home.poster-title")} />
        </video>
    </section>
)
