import React, { Component } from 'react'
import Translate from 'react-translate-component'
import ButtonPreviewVideo from '../common/btnpreviewvideo'
import ModalVideo from '../common/modalvideo'

import TEDx from '../../../imgs/TEDx.jpg'
import TEDx2 from '../../../imgs/TEDx2.jpg'

var _t = require('counterpart')

export default class About extends Component {

    constructor(props) {
        super(props)
        this.state = { OpenModalVideo: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal() {
        this.setState({ OpenModalVideo: true })
        document.body.style.overflow = 'hidden';
    }

    closeModal() {
        this.setState({ OpenModalVideo: false })
        document.body.style.overflow = 'unset';
    }

    render() {
        return (
            <section id={_t("sec-ids.about")} className="commom-sec sec-about container-fluid">
                <div className="parallax">
                    <div className="container">
                        <div className="parallax-text">
                            <p><i className="fa fa-quote-left" aria-hidden="true"></i> 
                            <Translate content="about.parallax-text" />
                             <i className="fa fa-quote-right" aria-hidden="true"></i></p>
                            <p className="signature pull-right"><Translate content="about.parallax-signature" /></p>
                        </div>
                    </div>
                </div>
                <div className="section-body">
                    <div className="container">
                        <h1><Translate content="about.sec-title" /></h1>
                        <div className="content sec-bottom">
                            <div className="about-project">
                                <p><Translate content="about.sec-description1" /></p>
                                <p><Translate content="about.sec-description2" /></p>
                                <p><Translate content="about.sec-description3" /></p>
                            </div>
                            <div className="about-video">
                                <ButtonPreviewVideo 
                                    alt="Preview video TEDxUFPE" imgSrc={TEDx} imgSrc2={TEDx2}
                                    onClick={() => this.openModal()}
                                />
                                <p><Translate content="about.video-description" /></p>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo 
                    id="modal-about" src="//www.youtube.com/embed/KCv8km0wrJc" 
                    show={this.state.OpenModalVideo}
                    onClose={this.closeModal} 
                />
            </section>
        )
    }
}
