import React, { Component } from 'react'
import Translate from 'react-translate-component'
import ButtonPreviewVideo from '../common/btnpreviewvideo'
import TEDx from '../../../imgs/TEDx.jpg'
import TEDx2 from '../../../imgs/TEDx2.jpg'
import ModalVideo from '../common/modalvideo'
import CampanhaTratamento from '../../../imgs/products/campanha-naopareotratamento.png'
import CampanhaRafaMattos from '../../../imgs/products/campanha-rafamattos.jpg'
import CampanhaMulheremFoco from '../../../imgs/products/campanha3.jpg'

var _t = require('counterpart')

export default class Products extends Component {

    constructor(props) {
        super(props)
        this.state = {item: 1} 
        this.handleClick = this.handleClick.bind(this)
        this.refresh = this.refresh.bind(this)
        console.log(this.state.item + " inicial")
    }

    refresh() {
        this.setState({ item: 1 })
        document.getElementById("item1").style.display = "none"
        document.getElementById("item2").style.display = "none"
        document.getElementById("item3").style.display = "none"
        document.getElementById("item4").style.display = "none"
        document.getElementById("item5").style.display = "none"
    }

    handleClick(direction) {
            this.refresh()
            if (direction === "left") {
                var newItem = this.state.item
                newItem--
                if (this.state.item > 1) {
                    this.setState({selected: true, item: newItem})
                } else if (this.state.item === 1) {
                    this.setState({selected: true, item: 5})
                }
            } else {
                if (this.state.item < 5) {
                    var newItem2 = this.state.item
                    newItem2++
                    this.setState({selected: true, item: newItem2})
                    console.log(this.state.item + " no if right < 5")
                } else if (this.state.item == 5) {
                    this.setState({selected: true, item: 1})
                    console.log(this.state.item + " no if right == 5")
                }
            }
            console.log(this.state.item)
            document.getElementById("item" + this.state.item).style.display = "flex"
    }

    render() {
        return (
            <section id={_t("sec-ids.products")} className="commom-sec sec-products container-fluid">
                <div className="parallax">
                    <div className="container">
                        {/* <div className="parallax-text">
                            <p><i className="fa fa-quote-left" aria-hidden="true"></i> 
                            <Translate content="team.parallax-text" />
                            <i className="fa fa-quote-right" aria-hidden="true"></i></p>
                            <p className="signature pull-right"><Translate content="team.parallax-signature" /></p>
                        </div> */}
                    </div>
                </div>
                <div className="section-body">
                    <div className="container sec-bottom">
                        <h1><Translate content="products.sec-title" /></h1>
                        <i class="fa fa-angle-left fa-5x" id="arrowLeft" onClick={() => this.handleClick("left")}></i> 
                        <div className="carousel">
                            <div className="carousel-item" id="item1">
                                <div className="products-project">
                                    <h2><Translate content="products.product-webserie-title"/></h2>
                                    <p><Translate content="products.product-webserie" /></p>
                                    <p><Translate content="products.product-webserie2" /></p>
                                </div>
                                <div className="products-video">
                                    <div class="embed-responsive embed-responsive-16by9">
                                       <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/y9DcX-aiR4E" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" id="item2">
                                <div className="products-project">
                                <h2><Translate content="products.product-palestras-title"/></h2>
                                    <p><Translate content="products.product-palestras-desc1" /></p>
                                    <p><Translate content="products.product-palestras-desc2" /></p>
                                </div>
                                <div className="products-video">
                                    <div class="embed-responsive embed-responsive-16by9">
                                       <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/KCv8km0wrJc" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" id="item3">
                                <div className="products-project">
                                <h2><Translate content="products.product-podcasts-title"/></h2>
                                    <p><Translate content="products.product-podcasts-desc1" /></p>
                                    <p><Translate content="products.product-podcasts-desc1" /></p>
                                </div>
                                <div className="products-video">
                                    <div class="embed-responsive embed-responsive-16by9">
                                       <iframe class="embed-responsive-item" src="https://open.spotify.com/embed-podcast/show/0nuxToDsjNFUlFNUtjMxYv" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" id="item4">
                                <div className="products-project">
                                <h2><Translate content="products.product-campanhas-title"/></h2>
                                    <p><Translate content="products.product-campanhas-desc1" /></p>
                                    <p><Translate content="products.product-campanhas-desc2" /></p>
                                </div>
                                <div className="products-video">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <img src={CampanhaTratamento} alt="" className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" id="item5">
                                <div className="products-project">
                                <h2><Translate content="products.product-campanhascorporativas-title"/></h2>
                                    <p><Translate content="products.product-campanhascorporativas-desc1" /></p>
                                    <p><Translate content="products.product-campanhascorporativas-desc2" /></p>
                                </div>
                                <div className="products-video">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <img src={CampanhaMulheremFoco} alt="" className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-angle-right fa-5x" id="arrowRight" onClick={() => this.handleClick("right")}></i>  
                    </div>
                </div>
                <ModalVideo 
                    id="modal-about" src="//https://www.youtube.com/watch?v=y9DcX-aiR4E" 
                    show={this.state.OpenModalVideo}
                    onClose={this.closeModal} 
                />
            </section>
        )    
    }
}