import React, { Component } from 'react'
import Translate from 'react-translate-component'
import Sponsors from './contribute/sponsors'
import ModalForm from './contribute/modalform'
import ModalVideo from './common/modalvideo'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

export default class Contribute extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            openModalVideo: false, 
            openModalForm: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal(modal) {
        if (modal === "video") {
            this.setState({ openModalVideo: true })
        } else {
            this.setState({ openModalForm: true })
        }
        document.body.style.overflow = 'hidden';
    }

    closeModal() {
        this.setState({ openModalVideo: false, openModalForm: false })
        document.body.style.overflow = 'unset';
    }

    render() {
        return (
            <div>
                <section id="contribute" className="commom-sec sec-contrib container-fluid">
                    <div className="section-body container-fluid">
                        <div className="container">
                            <div className="content">
                                <div className="contrib-geral">
                                    <p><Translate content="contribute.page-description" /></p>
                                </div>
                            </div>
                            <div className="content">
                                <div className="contrib-question">
                                    <p><Translate content="contribute.as-person" /></p>
                                </div>
                            </div>
                            <div className="content">
                                <div className="contrib-person">
                                    <h2><Translate content="contribute.shopping-donating-title" /></h2>
                                    <p><Translate content="contribute.shopping-donating-description" /></p>
                                    <div className="contrib-button">
                                        <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=9NX3R3XX8EVCW&lc=BR&item_name=Alem%20da%20Cura&item_number=Alem%20da%20Cura&currency_code=BRL&bn=PP%2dDonationsBF%3abtn_donateCC_LG%2egif%3aNonHosted" className="btn" target="_blank" rel="noopener noreferrer" >
                                            <Translate content="contribute.shopping-donating-button"/></a>
                                    </div>
                                    <div className="contrib-person">
                                        <p><Translate content="contribute.volunteering1-description" /></p>
                                    </div>
                                </div>  
                            </div>
                            <div className="content">
                                <div className="contrib-question">
                                    <p><Translate content="contribute.as-company" /></p>
                                </div>
                            </div>
                            <div className="content">
                                <div className="contrib-company">
                                    <h2><Translate content="contribute.partner-title" /></h2>
                                    <p><Translate content="contribute.partner-description" /></p>
                                    <div className="contrib-company-email">
                                        <a href="mailto:contato@alemdacura.com">contato@alemdacura.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalVideo 
                        id="modal-contribute" src="//www.youtube.com/embed/KCv8km0wrJc" 
                        show={this.state.openModalVideo}
                        onClose={this.closeModal} 
                    />
                    <ModalForm
                        iframesrc="https://docs.google.com/forms/d/e/1FAIpQLScz8swSr6Hdzpk5g5WM33deFxYdkQ24pUmu0KXcdb_KCqhmfw/viewform" 
                        show={this.state.openModalForm}
                        onClose={this.closeModal}
                    />
                </section>
                <Sponsors />
            </div>
        )
    }
}