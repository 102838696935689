import React, { Component } from "react"
//import { HashLink as Link } from './utils/react-router-hash-link'
import Translate from 'react-translate-component'
import logoFull from '../../imgs/logo.png'
//import logoNav from '../../imgs/logo1.png'
import InstagramWidget from './utils/instagram-widget'
//var _t = require('counterpart')

const MainFooter = props => (
    <section id="main-footer" className="container-fluid">
        <footer className="container-fluid">
            <div className="container">
                <p>&copy; <Translate content="copyright.text" /> 2018</p>
            </div>
        </footer>
    </section>
)

class Footer extends Component {


    render() {
        return (
            <section id="footer" className="container-fluid">
                <footer>
                    <div className="container">
                        <div id="footer-text">
                            <div className="content">
                                <div id="footer-brand">
                                    <a href="/#"><img className="img-responsive"
                                        src={logoFull} alt="Logo Além da Cura" /></a>
                                </div>
                                
                            </div>
                            <div className="content">
                                <div id="footer-infos">
                                    <p><Translate content="footer.contacts-call" /></p>
                                    <p><i className="fa fa-map-marker fa-lg" aria-hidden="true"></i> Recife - PE</p>
                                    <p><i className="fa fa-envelope fa-lg" aria-hidden="true"></i> <a href="mailto:contato@alemdacura.com">contato@alemdacura.com</a></p>
                                </div>
                                <div id="footer-campaigns">
                                    <p><Translate content="press.last-camp" /></p>
                                    <ul>
                                        <li><a href="https://www.facebook.com/events/480064199058170/?acontext=%7B%22source%22%3A5%2C%22page_id_source%22%3A845608232190214%2C%22action_history%22%3A[%7B%22surface%22%3A%22page%22%2C%22mechanism%22%3A%22main_list%22%2C%22extra_data%22%3A%22%7B%5C%22page_id%5C%22%3A845608232190214%2C%5C%22tour_id%5C%22%3Anull%7D%22%7D]%2C%22has_source%22%3Atrue%7D" target="_blank" rel="noopener noreferrer"><i className="fa fa-heartbeat" aria-hidden="true"></i> <Translate content="press.camp1-title" /></a></li>
                                        <li><a href="//www.alemdacura.com/alemdaviagem" target="_blank" rel="noopener noreferrer"><i className="fa fa-plane" aria-hidden="true"></i> <Translate content="press.camp2-title" /></a></li>
                                    </ul>  
                                </div>
                            </div>
                        </div>
                        <div id="footer-instagram">
                            <InstagramWidget />
                        </div>
                    </div>
                </footer>
            </section>
        )
    }
}

export default props => (
    <div>
        <Footer />
        <MainFooter />
    </div>
)