import React, { Component } from 'react'
import Translate from 'react-translate-component'

import { Container, Row, Col } from 'react-bootstrap';

var _t = require('counterpart')

export default class OurNumber extends Component {

    constructor(props) {
        super(props)
        this.state = { OpenModalVideo: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal() {
        this.setState({ OpenModalVideo: true })
        document.body.style.overflow = 'hidden';
    }

    closeModal() {
        this.setState({ OpenModalVideo: false })
        document.body.style.overflow = 'unset';
    }

    render() {
        return (
            <section id={_t("sec-ids.about")} className="commom-sec container-fluid">
                <div style={{background: "#fff"}} className="section-body">
                    <div className="container">
                        <h1><Translate content="our-numbers.title" /></h1>
                        <Container>
                            <Row>
                                <Col sm={3}>
                                    <Row className="our-numbers">150 mil</Row>
                                    <Row><p className="text-center"><Translate content="our-numbers.people-engaged-in-events-in-person" /></p></Row>
                                </Col>
                                <Col sm={3}>
                                    <Row className="our-numbers">2 mil</Row>
                                    <Row><p className="text-center"><Translate content="our-numbers.mobilized-supporters" /></p></Row>
                                </Col>
                                <Col sm={3}>
                                    <Row className="our-numbers">60</Row>
                                    <Row><p className="text-center"><Translate content="our-numbers.women-shared-with-us-your-trajectories" /></p></Row>
                                </Col>
                                <Col sm={3}>
                                    <Row className="our-numbers">5</Row>
                                    <Row><p className="text-center"><Translate content="our-numbers.continents-of-the-world-that-traveled" /></p></Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </section>
        )
    }
}
