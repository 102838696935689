import React, { Component } from 'react'
import Translate from 'react-translate-component'

import Bruna from '../../../imgs/team/bruna'
import Estefane from '../../../imgs/equipe_estefane.png'
import Lucas from '../../../imgs/team/lucas.png'
import Arthur from '../../../imgs/team/arthur.jpg'
import Felipe from '../../../imgs/team/lipe.jpg'
import Manuela from '../../../imgs/team/manu.jpg'
import Thamires1 from '../../../imgs/team/thami1.jpg'
import Rayane from '../../../imgs/team/ray.jpeg'
import AnaGabriella from '../../../imgs/team/ana-gabriella'
import Sofia from '../../../imgs/team/ana-sofia'
import Bruno from '../../../imgs/team/bruno.jpg'
import Cinthya from '../../../imgs/team/cinthya'
import Douglas from '../../../imgs/team/douglas.jpg'
import Giseli from '../../../imgs/team/giseli.jpeg'
import Joelli from '../../../imgs/team/joelli.jpg'
import Nagila from '../../../imgs/team/nagila'
import Nicole from '../../../imgs/team/nicole.jpeg'
import Pedro1 from '../../../imgs/team/pedro'
import Roberta from '../../../imgs/team/roberta'
import Tamires from '../../../imgs/team/tamires'
import Tatiany from '../../../imgs/team/tatiany'
import Luanna from '../../../imgs/team/luanna.jpeg'
import Karinne from '../../../imgs/team/karinne.jpg'
import Rayanne from '../../../imgs/team/ray.jpeg'
import Amanda from '../../../imgs/team/amanda.jpeg'
import Flaviano from '../../../imgs/team/flaviano.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

var _t = require('counterpart')


export default class Team extends Component {
    constructor(props) {
        super(props)
        this.state = { selected: false, page: 1}
        console.log(this.state.page)
        this.handleClick = this.handleClick.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    refresh() {
        this.setState({ selected: false, page: 1 })
        document.getElementById("page1").style.display = "none"
        document.getElementById("page2").style.display = "none"
        document.getElementById("page3").style.display = "none"
        document.getElementById("page4").style.display = "none"
        document.getElementById("page5").style.display = "none"
        document.getElementById("page6").style.display = "none"
        document.getElementById("page7").style.display = "none"
        document.getElementById("page8").style.display = "none"
        document.getElementById("page9").style.display = "none"
        document.getElementById("page10").style.display = "none"
        document.getElementById("page11").style.display = "none"
        // document.getElementById("page12").style.display = "none"
        // document.getElementById("page13").style.display = "none"

        // if (this.state.page.length > 5) {
        //     document.getElementById("dot" + this.state.page[4] + this.state.page[5]).style.backgroundColor = ""
        // } else {
        //     document.getElementById("dot" + this.state.page[4]).style.backgroundColor = ""
        // }
    }
    
    handleClick(direction) {
        let currentPage = this.state.page
        console.log(direction=== "left", "-------direction");
        console.log("current page: " + currentPage)
            this.refresh()
            if(direction === "left") {
                console.log(this.state.page, "this.state.page------");
                if (currentPage > 1) {
                    console.log(currentPage, "currentPage------");
                    this.setState({ selected: true, page: currentPage - 1})
                } else {
                    this.setState({ selected: true, page: 11})
                }
            } else {
                console.log(this.state.page)
                if (currentPage < 11) {
                    let newPage = currentPage+1
                    console.log(newPage)
                    this.setState({ selected: true, page: newPage})
                    console.log(this.state.page)
                } else {
                    this.setState({ selected: true, page: 1})
                }
            }
            console.log(this.state.page)
            document.getElementById("page" + this.state.page).style.display = "inline-block"

            // if (page.length > 5) {
            //     document.getElementById("dot" + page[4] + page[5]).style.backgroundColor = "#ffa07d"    
            // } else {
            //     document.getElementById("dot" + page[4]).style.backgroundColor = "#ffa07d"
            // }
            

        
    }

    render() {
        return (    
            <section id={_t("sec-ids.team")} className="sec-team container-fluid">
                <div className="parallax">
                    <div className="container">
                        <div className="parallax-text">
                            <p><i className="fa fa-quote-left" aria-hidden="true"></i> 
                            <Translate content="team.parallax-text" />
                                <i className="fa fa-quote-right" aria-hidden="true"></i></p>
                            <p className="signature pull-right"><Translate content="team.parallax-signature" /></p>
                        </div>
                    </div>
                </div>
                <div className="section-body">
                    <div className="container">
                        <h1><Translate content="team.sec-title" /></h1>
                        <i class="fa fa-angle-left fa-5x" id="arrowRight" onClick={() => this.handleClick("left")}></i>
                        <div className="carousel-container">
                            <div className="carousel-item" id="page1">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Bruna} alt="" className="img-responsive"/>
                                            <h2>Bruna Monteiro</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-description">
                                            <h4><Translate content="team.bruna-role" /></h4>
                                            <h4><span className="span-email">bruna@alemdacura.com</span></h4>
                                            <p><Translate content="team.bruna-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Estefane} alt="" className="img-responsive" />
                                            <h2>Estéfane Oliveira</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <h4><Translate content="team.estefane-role" /></h4>
                                            <h4><span className="span-email" >estefane@alemdacura.com</span></h4>
                                            <p><Translate content="team.estefane-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page2">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Sofia} alt="" className="img-responsive" />
                                            <h2>Ana Sofia</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.sofia-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Amanda} alt="" className="img-responsive" />
                                            <h2>Amanda Barbosa</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.amanda-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page3">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={AnaGabriella} alt="" className="img-responsive" />
                                            <h2>Ana Gabriella</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.ana-gabriella-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Arthur} alt="" className="img-responsive" />
                                            <h2>Arthur Lafayette</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.arthur-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page4">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Bruno} alt="" className="img-responsive" />
                                            <h2>Bruno Silva</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.bruno-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Cinthya} alt="" className="img-responsive" />
                                            <h2>Cinthya Lins</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.cinthya-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page5">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Douglas} alt="" className="img-responsive" />
                                            <h2>Douglas Henrique</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.douglas-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Felipe} alt="" className="img-responsive" />
                                            <h2>Felipe Pereira</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.felipe-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" id="page6">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Joelli} alt="" className="img-responsive" />
                                            <h2>Joelli Azevedo</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.joelli-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Karinne} alt="" className="img-responsive" />
                                            <h2>Karinne Evelyn</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.karinne-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page7">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Luanna} alt="" className="img-responsive"/>
                                            <h2>Luanna Oliveira</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-description">
                                            <p><Translate content="team.luanna-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Lucas} alt="" className="img-responsive" />
                                            <h2>Lucas Rodrigues</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.lucas-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page8">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Manuela} alt="" className="img-responsive" />
                                            <h2>Manuella Valença</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.manuella-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Nagila} alt="" className="img-responsive" />
                                            <h2>Nágila Alves</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.nagila-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page9">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Nicole} alt="" className="img-responsive" />
                                            <h2>Nicole Charron</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.nicole-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Pedro1} alt="" className="img-responsive" />
                                            <h2>Pedro Farias</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.pedro-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="carousel-item" id="page10">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Rayanne} alt="" className="img-responsive" />
                                            <h2>Rayanne Sena</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.rayanne-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Tatiany} alt="" className="img-responsive" />
                                            <h2>Tatiany Brandão</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.tatiany-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" id="page11">
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Flaviano} alt="" className="img-responsive" />
                                            <h2>Flaviano Teixeira</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.flaviano-description" /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">
                                        <div className="member-img">
                                            <img src={Thamires1} alt="" className="img-responsive" />
                                            <h2>Thamires Araújo</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-member">
                                    <div className="member-box">  
                                        <div className="member-description"> 
                                            <p><Translate content="team.thamires-description" /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-angle-right fa-5x" id="arrowRight" onClick={() => this.handleClick("right")}></i>
                    </div>

                    {/* <div className="dots">
                        <span onClick={() => this.handleClick("page1")} class="dot" id="dot1"></span>
                        <span onClick={() => this.handleClick("page2")} class="dot" id="dot2"></span>
                        <span onClick={() => this.handleClick("page3")} class="dot" id="dot3"></span>
                        <span onClick={() => this.handleClick("page4")} class="dot" id="dot4"></span>
                        <span onClick={() => this.handleClick("page5")} class="dot" id="dot5"></span>
                        <span onClick={() => this.handleClick("page6")} class="dot" id="dot6"></span>
                        <span onClick={() => this.handleClick("page7")} class="dot" id="dot7"></span>
                        <span onClick={() => this.handleClick("page8")} class="dot" id="dot8"></span>
                        <span onClick={() => this.handleClick("page9")} class="dot" id="dot9"></span>
                        <span onClick={() => this.handleClick("page10")} class="dot" id="dot10"></span>
                        <span onClick={() => this.handleClick("page11")} class="dot" id="dot11"></span>
                    </div> */}
                </div>

        </section>
    )
    }
}

