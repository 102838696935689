import React, { Component } from "react"
import base from '../../base'
import $ from 'jquery'
import './wg-instagram'
import Translate from 'react-translate-component'

export default class InstagramWidget extends Component {
        constructor(props) {
            super(props)
            this.state = {
                data: false,
                initialization: true
            }
        }

        componentWillMount() {
            base.fetch('instagramInfos', {
                context: this,
                asArray: true
            }).then(data => {
                this.setState({data: data})
            }).catch(error => {
                console.log("fetching instagram infos error: ", error)
            })
        }
    
        render() {
            if(this.state.data && this.state.initialization) {
                const infos = this.state.data
                $("#instagram-widget").instagram(infos)
                this.setState({initialization: false})
            }
            return (
                <div id ="instagram-widget">
                    <h2>INSTAGRAM</h2>
                    <div id="div-content1" className="content"></div>
                    <div id="div-content2" className="content"></div>
                    <div id="div-content3" className="content"></div>
                    <div className="div-place-holder">
                        <p><Translate content="footer.instagram-erro" /></p>
                        <a title="Além da Cura Instagram" href="https://www.instagram.com/alemdacura/" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>    
            )
        }
    }

