import Rebase from 're-base'
import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyCe9jQAxoTj6bxinhuS7RoDkG45J23JcQM",
    authDomain: "alemdacura-app.firebaseapp.com",
    databaseURL: "https://alemdacura-app.firebaseio.com",
    projectId: "alemdacura-app",
    storageBucket: "alemdacura-app.appspot.com",
    messagingSenderId: "902979935117"
}

/*const config = {
    apiKey: "AIzaSyBcSuiVZijKl9NUlpgmhpBpoxZ3E4qulUI",
    authDomain: "https://teste-877bb.firebaseapp.com/",
    databaseURL: "https://teste-877bb.firebaseio.com",
    projectId: "teste-877bb",
    storageBucket: "teste-877bb.appspot.com"
}*/

const firebaseApp = firebase.initializeApp(config)
const db = firebase.database(firebaseApp)
const base = Rebase.createClass(db)

export default base