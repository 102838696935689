import React from "react";
import Translate from "react-translate-component";

import { Progress } from "react-sweet-progress";
import { Webseries } from "../../../imgs/webserie.png";
import "react-sweet-progress/lib/style.css";

var _t = require("counterpart");

export default (props) => (
  <section className="commom-sec">
    <div className="sec-webseries">
      <div className="button-webseries">
        <a
          href="https://www.instagram.com/alemdacura/channel/"
          className="btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate content="webseries.webseries-button" />
        </a>
      </div>
    </div>
  </section>
);
