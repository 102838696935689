import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoadingIcon from '../../../imgs/loading-icon2.gif'

class ModalVideo extends Component {

    constructor(props) {
        super(props)
        this.state = { isLoading: true }
        this.onLoad = this.onLoad.bind(this)
    }

    onLoad() {
        document.getElementById("loading").style.display = "none"
    }

    componentDidUpdate() {
        if (this.props.show) {
            console.log(this.refs)
            this.refs.iframevideo.addEventListener('load', this.onLoad);
        }
    } 

    render() {

        if(!this.props.show) {
            return null;
        }
 
        return (
            <section id={this.props.id} className="modalvideo-backdrop" onClick={this.props.onClose}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-container">
                        <header className="modal-header">
                            <button type="button" className="close" onClick={this.props.onClose}><span aria-hidden="true">&times;</span></button>
                        </header>
                        <div className="modal-body">
                            <img id="loading" src={LoadingIcon} alt="Loading Icon" className="img-responsive" />
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe ref="iframevideo" title="modalvideo" className="embed-responsive-item"
                                    src={this.props.src} 
                                    allowFullScreen
                                    frameBorder="0">
                                </iframe>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

ModalVideo.propTypes = {
    id: PropTypes.string,
    src: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
}

export default ModalVideo